import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/compat/app';
// import {getAnalytics} from "firebase/analytics";


const firebaseConfig = {    
  apiKey: "AIzaSyB0couh0japxmhOVE2hCVs8i2kVvCnF8jU",
  authDomain: "portfolio-f8b97.firebaseapp.com",
  databaseURL: "https://portfolio-f8b97-default-rtdb.firebaseio.com",
  projectId: "portfolio-f8b97",
  storageBucket: "portfolio-f8b97.appspot.com",
  messagingSenderId: "757939256722",
  appId: "1:757939256722:web:776609414bf23abdad154b",
  measurementId: "G-83H2ZHD12Y"
};
firebase.initializeApp(firebaseConfig);
// const anal = getAnalytics(firebaseApp);

createApp(App).use(store).use(router).mount('#app')
